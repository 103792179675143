import React, { useState } from 'react'
import { capitalizeFirstLetter, checkMark } from 'utils/forms'

import HelperText from '../layouts/HelperText'
import { Input } from '@mehilainen/design-system'
import InputContainer from '../layouts/InputContainer'

type Props = {
  name: string
  nameType: NameType
  setName: (value: string) => void
  disabled?: boolean
}

export const NameField = ({ disabled, name, nameType, setName }: Props) => {
  const [nameHelperText, setNameHelperText] = useState<string>('')
  const [hasFocus, setHasFocus] = useState<boolean>(false)

  const getNameLabel = (type: NameType): string => {
    switch (type) {
      case 'FIRSTNAME':
        return 'etunimi'
      case 'LASTNAME':
        return 'sukunimi'
      case 'NAME':
        return 'nimi'
      default:
        return 'nimi'
    }
  }
  const nameLabel = getNameLabel(nameType)
  const capitalLabel = capitalizeFirstLetter(nameLabel)

  const isNameError = (name: string, disabled?: boolean): boolean | undefined =>
    disabled ? undefined : !name.trim()

  function handleNameInputChange(value: string): void {
    setName(value)
    if (!value.trim()) {
      // Visually hidden
      setNameHelperText(`${capitalLabel} ei voi olla tyhjää`)
    } else if (!name.trim()) {
      // Visually hidden
      setNameHelperText(`${capitalLabel} on oikean muotoinen`)
    }
    return
  }

  return (
    <InputContainer>
      <Input
        id={nameLabel}
        value={name}
        label={
          checkMark((hasFocus && isNameError(name, disabled)) || undefined) +
          `${capitalLabel} *`
        }
        required
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onChange={e => handleNameInputChange(e.target.value)}
        type="text"
        disabled={disabled}
        error={hasFocus && isNameError(name, disabled)}
      />
      <HelperText
        className="visually-hidden"
        error={!name.trim()}
        htmlFor={`${nameLabel}-input`}
      >
        {nameHelperText}
      </HelperText>
    </InputContainer>
  )
}

export type NameType = 'FIRSTNAME' | 'LASTNAME' | 'NAME'
