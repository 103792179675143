import { Button, H3 } from '@mehilainen/design-system'
import React, { useState } from 'react'

import { EmailField } from './fields/EmailField'
// import { MarketingPermission } from './fields/MarketingPermission'
import { NameField } from './fields/NameField'
import { NewCustomerData } from 'requests/magento_customer/createCustomer'
import { NewPasswordField } from './fields/NewPasswordField'
import { RepeatNewPasswordField } from './fields/RepeatNewPasswordField'
import { passwordIsValid } from 'utils/login'
import styled from 'styled-components'

type Props = {
  createCustomer: (data: NewCustomerData) => void
  heading?: string
  loading: boolean
}

export default ({ createCustomer, heading, loading }: Props) => {
  const [firstname, setFirstName] = useState<string>('')
  const [lastname, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [emailInvalidOrUnavailable, setEmailInvalidOrUnavailable] = useState<
    boolean | undefined
  >(undefined)
  const [password, setPassword] = useState<string>('')
  const [repeatPassword, setRepeatPassword] = useState<string>('')
  // const [marketingPermission, setMarketingPermission] = useState<boolean>(false)

  const disabled =
    !email.trim() ||
    emailInvalidOrUnavailable ||
    !firstname.trim() ||
    !lastname.trim() ||
    !password.trim() ||
    !passwordIsValid(password) ||
    !repeatPassword ||
    password !== repeatPassword

  return (
    <Columns>
      <div>
        {heading ? <H3>{heading}</H3> : null}
        <NameField
          nameType="FIRSTNAME"
          name={firstname}
          setName={setFirstName}
        />
        <NameField nameType="LASTNAME" name={lastname} setName={setLastName} />
      </div>
      <div>
        <H3>Kirjautumistiedot</H3>
        <EmailField
          email={email}
          setEmail={setEmail}
          emailInvalidOrUnavailable={emailInvalidOrUnavailable}
          setEmailInvalidOrUnavailable={setEmailInvalidOrUnavailable}
          checkifAvailable={true}
        />
        <NewPasswordField newPassword={password} setNewPassword={setPassword} />
        <RepeatNewPasswordField
          repeatNewPassword={repeatPassword}
          setRepeatNewPassword={setRepeatPassword}
          newPassword={password}
        />
        {/* We leave these out for the moment but might need them later */}
        {/*
        <MarketingPermission
          marketingPermission={marketingPermission}
          setMarketingPermission={setMarketingPermission}
        />
        */}
        <Button
          loading={loading}
          large
          disabled={disabled}
          onClick={e => {
            e.preventDefault()
            createCustomer({
              firstname,
              lastname,
              email,
              password,
              is_subscribed: false,
            })
          }}
        >
          LUO KÄYTTÄJÄTUNNUS
        </Button>
      </div>
    </Columns>
  )
}

const Columns = styled.form`
  display: flex;
  flex-direction: column;
  gap: 50px;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  @media (min-width: ${props => props.theme.mobileBreakpoint}) {
    justify-content: center;
    flex-direction: row;

    > div {
      max-width: 550px;
    }
  }
`
