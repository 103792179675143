import React, { useContext, useEffect, useState } from 'react'
import { loginGA, registrationGA } from 'gtag.js/utils'

import ContentContainer from 'components/ContentContainer'
import CreateCustomerForm from 'components/forms/CreateCustomerForm'
import { H1 } from '@mehilainen/design-system'
import { LoginContext } from 'state/providers/LoginProvider'
import { LoginData } from 'requests/magento_login/loginCustomer'
import { NewCustomerData } from 'requests/magento_customer/createCustomer'
import { NotificationContext } from 'state/providers/NotificationProvider'
import { getAuthenticationError } from './Login'
import { navigate } from 'gatsby'
import notForLoggedInUsers from 'utils/login/notForLoggedInUsers'
import styled from 'styled-components'
import { unexpectedErrorDefaultMessage } from 'utils/errorHandling'

export type NotificationMessage = {
  type?: NotificationMessageType
  message?: string
}

type NotificationMessageType = 'success' | 'error' | 'warning'

export default () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { isLoggedIn, createNewCustomer, loginCustomer, logoutCustomer } =
    useContext(LoginContext)
  const { setNotification } = useContext(NotificationContext)

  //TODO: move to own hook
  const handleLogin = async ({ email, password }: LoginData): Promise<void> => {
    setLoading(true)
    let newCustomerToken
    try {
      newCustomerToken = await loginCustomer(password, email)
      loginGA()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      newCustomerToken && logoutCustomer(newCustomerToken)
      const message = getAuthenticationError(err)
      setNotification(
        {
          type: 'error',
          message,
        },
        5000
      )
      console.error('Customer login failed', err)
      return
    }
    navigate('/profile')
  }

  //TODO: move to own hook
  const createCustomer = async (
    newCustomerData: NewCustomerData
  ): Promise<void> => {
    setLoading(true)
    const { email, password } = newCustomerData
    try {
      await createNewCustomer(newCustomerData)
      registrationGA()
      setLoading(false)
      handleLogin({ password, email })
    } catch (err) {
      setLoading(false)
      setNotification(
        {
          type: 'error',
          message: unexpectedErrorDefaultMessage,
        },
        5000
      )
      console.error('Failed creating new customer', err)
    }
  }

  useEffect(() => {
    notForLoggedInUsers(isLoggedIn, '/profile')
  }, [isLoggedIn])

  return (
    <ContentContainer>
      <Header>
        <H1>Luo uusi tili</H1>
        <p>
          Voit täyttää osoitetiedot myöhemmin tilauksen yhteydessä tai
          päivittämällä tiedot profiilissasi.
        </p>
      </Header>
      <CreateCustomerForm
        heading="Henkilökohtaiset tiedot"
        loading={loading}
        createCustomer={createCustomer}
      />
    </ContentContainer>
  )
}

const Header = styled.div`
  text-align: center;
  padding-bottom: 50px;
  max-width: 500px;
  margin: auto;
`
